import * as React from "react"

import { Layout, BlogPostsList, Breadcrumb } from "../components"
import { graphql } from "gatsby"

const BlogPage = ({ data }) => {
  const { frontmatter: content } = data.content

  return (
    <Layout>
      <div className="active-dark">
        <Breadcrumb
          title="Blog"
          background={content.background}
          backgroundMobile={content.backgroundMobile}
        />

        <div className="rn-blog-area ptb--120 brand-separation">
          <div className="container">
            <BlogPostsList posts={data.blogPosts.edges} />
            <div className="row mt--20">
              <div className="col-lg-12">
                {/* Start Pagination Area */}
                {/* <Pagination /> */}
                {/* End Pagination Area */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const guery = graphql`
  {
    content: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "blog" } }
    ) {
      frontmatter {
        background
        backgroundMobile
      }
    }

    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "blog" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            category
            thumbnail
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default BlogPage
